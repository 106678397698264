import React from "react";
import { graphql } from "gatsby";
import BlogElem from "../components/blog/blog";
import Posts from "../components/blog/posts";
import CategoryMenu from "../components/blog/category-menu";
import Seo from "../components/global/seo";
import convertId from "../utilities/convert-id";
import Content from "../components/utilities/content";
import CmsImage from "../components/utilities/cms-image";
import VideoPosts from "../components/blog/video-posts";
import IpTracker from "../components/utilities/ip-tracker";
import FooterCTA from "../components/sections/footer-cta";
import { Helmet } from "react-helmet";

const Category = ({
  data: {
    allWpPost: posts,
    allWpCategory: categories,
    wpPage: page,
    wpCategory: category,
    wp: {
      themeSettings: { themeSettings },
    },
  },
  pageContext: { totalPages, currentPage, baseUri },
}) => {
  return (
    <>
      <Helmet
        bodyAttributes={{
          class: `page-${convertId(category.name)}`,
        }}
      ></Helmet>
      <Seo
        title={
          category.page?.metaTitle ? category.page.metaTitle : category.name
        }
        description={
          category.page?.metaDescription
            ? category.page.metaDescription
            : category.description
        }
        themeSettings={themeSettings}
      />

      {page.sections.sections &&
        page.sections.sections.map((section, i) => (
          <section
            id={convertId(section.name)}
            className="page-title section-title-with-background-image"
          >
            <CmsImage className="section-img" image={section.image} />
            <div className="container">
              <div className="sz wrapper">
                <Content className="content-title-bg-image">
                  {section.content}
                </Content>
              </div>
            </div>
          </section>
        ))}

      <CategoryMenu tax={category} categories={categories.edges} />
      <BlogElem>
        {category.categorySettings?.useVideoLayout ? (
          <VideoPosts
            posts={posts}
            totalPages={totalPages}
            currentPage={currentPage}
            baseUri={baseUri}
            type="category"
          />
        ) : (
          <Posts
            posts={posts}
            totalPages={totalPages}
            currentPage={currentPage}
            baseUri={baseUri}
            type="category"
          />
        )}
      </BlogElem>
      <IpTracker />
      <FooterCTA hideFooterCta={category.page.hideFooterCta} />
    </>
  );
};

export const pageQuery = graphql`
  query Category($id: String!, $perPage: Int!, $skipItems: Int!) {
    wpCategory(id: { eq: $id }) {
      page {
        fieldGroupName
        metaTitle
        metaDescription
        pageCssOverrides
        hideFooterCta
      }
      id
      slug
      uri
      parentId
      name
      description
      categorySettings {
        useVideoLayout
      }
    }

    wp {
      themeSettings {
        themeSettings {
          siteMetaTitle
          customCssOverrides
          logo {
            localFile {
              publicURL
            }
          }
        }
      }
    }

    allWpCategory(sort: { order: ASC, fields: categorySettings___order }) {
      edges {
        node {
          id
          uri
          slug
          name
          page {
            fieldGroupName
            metaTitle
            metaDescription
            pageCssOverrides
          }
          parentId
        }
      }
    }

    wpPage(isPostsPage: { eq: true }) {
      uri
      slug
      id
      title
      sections {
        fieldGroupName
        sections {
          ... on WpPage_Sections_Sections_SectionTitleWithBackgroundImage {
            fieldGroupName
            name
            content
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
                extension
                publicURL
              }
            }
          }
        }
      }
    }

    allWpPost(
      sort: { order: DESC, fields: date }
      limit: $perPage
      skip: $skipItems
      filter: { categories: { nodes: { elemMatch: { id: { eq: $id } } } } }
    ) {
      edges {
        node {
          id
          title
          uri
          date(fromNow: true, formatString: "MMM DD YYYY")
          categories {
            nodes {
              id
              uri
              slug
              name
            }
          }
          tags {
            nodes {
              id
              uri
              slug
              name
            }
          }
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 760, height: 427)
                }
                extension
                publicURL
              }
            }
          }
          excerpt
          astroturfTv {
            fieldGroupName
            astroturfTvEmbed
          }
        }
      }
    }
  }
`;

export default Category;
